import React, { FC, useState, useContext, ReactNode } from 'react';
import { Navbar, Container, Nav, NavDropdown, Spinner } from 'react-bootstrap';
import { Context } from '..';
import { observer } from 'mobx-react-lite'
import { IYearsResponse } from '../models/response/YearsResponse';

const MenuForm: FC = () => {

    const [yearsLoaded, setYearsLoaded] = useState<Boolean>(false);
    const [years, setYears] = useState<Array<IYearsResponse>>([]);
    const { store } = useContext(Context)

    const getYears = async () => {
        if (!yearsLoaded) {
            const response = await store.getYears();
            setYears(response);
            setYearsLoaded(true)
        }
    };
    
    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/">Меню</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown 
                            title="Журнал соглашений"
                            onClick={getYears}
                        >
                            { years.length === 0 && 
                                <NavDropdown.Header> 
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> 
                                </NavDropdown.Header>
                            }
                            {years.map(year => {
                                    return <NavDropdown.Item 
                                        key={year.year_of_entry}
                                        href={`/journal/${year.year_of_entry}`}
                                    >{year.year_of_entry}
                                    </NavDropdown.Item>
                                }
                            )}
                        </NavDropdown>
                        <Nav.Link href="/reports">Отчеты</Nav.Link>
                        <Nav.Link href="#pricing">Адвокаты</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                    <Nav className="ms-auto">
                        <Nav.Link 
                            href='/'
                            onClick={store.logout}
                        >
                            Выйти
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <br />
        </div>

    );
};

export default observer(MenuForm);
