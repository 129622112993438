import axios, { AxiosRequestConfig } from 'axios';

export const API_URL = `https://gornerspb.ru/api`

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
})

$api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (!config.headers) {
            throw new Error();
        }
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
        return config;
    }
)

export default $api;
