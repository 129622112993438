import { AxiosResponse } from 'axios';
import $api from '../http';
import { IYearsResponse } from '../models/response/YearsResponse';
import { IMonthsResponse } from '../models/response/MonthsResponse';
import { IJournalResponse } from '../models/response/JournalReponse';
import { IReportLawyer } from '../models/response/ReportLawyer';

export default class DatabaseService {
    static async getYears(): Promise<AxiosResponse<Array<IYearsResponse>>> {
        return $api.get(
            '/user/getYears'
        )
    }

    static async getReportYears(): Promise<AxiosResponse<Array<IYearsResponse>>> {
        return $api.get(
            '/user/getReportYears'
        )
    }

    static async getReportMonths(year: number): Promise<AxiosResponse<IMonthsResponse[]>> {
        return $api.get(
            `/user/getReportMonths/${year}`
        )
    }

    static async getReportLawyers(year: number, month: string): Promise<AxiosResponse<IReportLawyer[]>> {
        return $api.get(
            `/user/getReportLawyers/${year}/${month}`
        )
    }

    static async getJournal(year: string): Promise<AxiosResponse<Array<IJournalResponse>>> {
        return $api.get(
            `/user/getJournal/${year}`
        );
    }

    static async getReport(year: number, month: string, lawyer: string) {
        return $api.get(
            `/user/getReport/${year}/${month}/${lawyer}`
        )
    }

    static async getReportsTable() {
        return $api.get(
            `/user/getAllReports`,
        )
    }
}
