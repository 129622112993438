import React, { FC, useContext, useEffect, useState }from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import LoginForm from './components/loginForm';
import { Context } from '.';
import { observer } from 'mobx-react-lite';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Spinner } from 'react-bootstrap';
import MenuForm from './components/menuForm';
import MainPage from './components/mainPage';
import JournalTable from './components/journalTable';
import ReportsMainTable from './components/reportsMainTable';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const App: FC = () => {
  const { store } = useContext(Context);
  const { sendMessage, lastMessage, readyState } = useWebSocket('wss://gornerspb.ru');


  useEffect(() => {
    console.log('check_token');
    if (localStorage.getItem('token')) {
      store.checkAuth();
    }
    document.title = 'Intelligence';
  }, [])

  if (store.isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner> 
    )
  }

  if (!store.isAuth) {
    return (
      <Container>
        <div className="d-flex justify-content-center">
          <h1> { store.isAuth ? `Пользователь авторизован ${store.login}` : 'Войдите в систему' }</h1>
        </div>
        <LoginForm />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <div className="d-flex justify-content-center">
          <h1> { 'Intelligence' }</h1>
        </div>
      </Row>
      <Row>
        <MenuForm />
      </Row>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/journal/:year" element={<JournalTable />} />
          <Route path="/reports" element={<ReportsMainTable />} />
        </Routes>
      </BrowserRouter>

    </Container>
  );
}

export default observer(App);
