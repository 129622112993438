import React from 'react';
import { Table, Container, Spinner } from 'react-bootstrap';
import { Context } from '..';
import { IJournalResponse } from '../models/response/JournalReponse';
import DatabaseService from '../services/DatabaseService';
type TJournalState = {
    journal: IJournalResponse[],
}

type TJournalProps = {
}

function ddmmyyyy(datePassed: Date, separator: string) {
    const date = new Date(datePassed);
    let dd = date.getDay() + 1;
    let mm = date.getMonth() + 1;
    return [
        (dd>9 ? '' : '0') + dd,
        (mm>9 ? '' : '0') + mm,
        date.getFullYear(),
    ].join(separator)
}

class JournalTable extends React.Component<TJournalProps, TJournalState> {

    constructor(props: TJournalProps) {
        super(props);
        this.state = {
            journal: []
        }
        const year = window.location.href.split('/').slice(-1)[0];
        DatabaseService.getJournal(year)
            .then((x) => {return x.data})
            .then((journal) => {
                console.log('journal :>> ', journal);
                this.setState({journal})
            });
    }

    render() {
        return (
            <Container>
                {
                    this.state.journal.length === 0 &&
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> 
                }
                <Table striped bordered responsive hover size="sm">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Адвокат</th>
                            <th>Клиент</th>
                            <th>Описание</th>
                            <th>Стоимость</th>
                            <th>Дата заключения</th>
                            <th>Дата внесения</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.journal.map((agreement) => {
                            return (
                                <tr>
                                    <td>
                                        {agreement.number}
                                    </td>
                                    <td>
                                        {agreement.lawyer}
                                    </td>
                                    <td>
                                        {agreement.client}
                                    </td>
                                    <td>
                                        {agreement.description}
                                    </td>
                                    <td>
                                        {agreement.cost}
                                    </td>
                                    <td>
                                        {ddmmyyyy(agreement.agr_date, '.')}
                                    </td>
                                    <td>
                                        {ddmmyyyy(agreement.entry_date, '.')}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>

                </Table>
            </Container>
        )
    }
} 
export default JournalTable;
