import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react-lite'

const MainPage: FC = () => {
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md="auto"><h1>Выберете пункт в меню для получения данных</h1></Col>
            </Row>
        </Container>
    );
};

export default observer(MainPage);
