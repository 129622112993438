import { AxiosResponse } from 'axios';
import $api from '../http';
import { IAuthResponse } from '../models/response/AuthResponse';

interface ILogoutResponse {
}
export default class AuthService {
    static async login(
        username: string, 
        password: string,
    ): Promise<AxiosResponse<IAuthResponse>> {
        return $api.post<IAuthResponse>(
            '/user/login',
            { login: username, password }
        )
    }

    static async logout(): Promise<AxiosResponse<ILogoutResponse>> {
        return $api.get<ILogoutResponse> (
            '/user/logout'
        )
    }
}
