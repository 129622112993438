import React, { FC, useState, useContext, useRef, useEffect } from 'react';
import { Form, Row, Col, Tabs, Tab, Button, Container, Nav, Table, Spinner } from 'react-bootstrap';
import { Context } from '..';
import { observer } from 'mobx-react-lite'
import { IYearsResponse } from '../models/response/YearsResponse';
import { IMonthsResponse } from '../models/response/MonthsResponse';
import { IReportLawyer } from '../models/response/ReportLawyer';
import { IReportModel } from '../models/response/ReportModel';
import DatabaseService from '../services/DatabaseService';
import { IReports } from '../models/response/FullReportsResponse'

const monthsMap = new Map<string, string>([
    ["Jan", "Январь"],
    ["Feb", "Февраль"],
    ["Mar", "Март"],
    ["Apr", "Апрель"],
    ["May", "Май"],
    ["Jun", "Июнь"],
    ["Jul", "Июль"],
    ["Aug", "Август"],
    ["Sep", "Сентябрь"],
    ["Oct", "Октябрь"],
    ["Nov", "Ноябрь"],
    ["Dec", "Декабрь"],
])

interface ITotalDebit {
    [year: number]: {[month: string]: {[lwr: string]: number}}
}

const ReportsMainTable: FC = () => {
    const [reports, setReports] = useState<IReports>({});
    const [totalDebit, setTotalDebit] = useState<ITotalDebit>({});
    const { store } = useContext(Context)

    const getReports = async() => {
        const response = await store.getAllReports();
        console.log('response :>> ', response);
        setReports(response);

        for (let y = 0; y < Object.keys(response).length; y++) {
            const element = Object.keys(response)[y];
            let auxTotalDebit = totalDebit;
            auxTotalDebit[Number(element)] = {}

            for (let m = 0; m < Object.keys(response[element]).length; m++) {
                const month = Object.keys(response[element])[m];
                auxTotalDebit[Number(element)][month] = {}

                for (let l = 0; l < Object.keys(response[element][month]).length; l++) {
                    const lwr = Object.keys(response[element][month])[l];
                    let totalSum = 0;
                    auxTotalDebit[Number(element)][month][lwr] = 0;

                    for (let index = 0; index < response[element][month][lwr].length; index++) {
                        const report = response[element][month][lwr][index];
                        totalSum += report.debit;
                    }

                    auxTotalDebit[Number(element)][month][lwr] = totalSum;
                    setTotalDebit(auxTotalDebit);
                }
                
            }
            
        }
    };

    useEffect(() => {
        getReports();
    }, []);

    
    
    return (
        <Container>
            {
                Object.keys(reports).length > 0 &&
                <Tabs
                    defaultActiveKey={Object.keys(reports)[0]}
                    id={`year-tabs`}
                    className="mb-3"
                >
                    {Object.keys(reports).map(year => {
                        return (
                            <Tab
                                eventKey={year}
                                title={year}
                            >
                                <Tabs
                                    defaultActiveKey={Object.keys(reports[Number(year)])[0]}
                                    id={`${year}-month-tabs`}
                                    className="mb-3"
                                >
                                    {Object.keys(reports[Number(year)]).map(month => {
                                        return (
                                            <Tab
                                                eventKey={month}
                                                title={monthsMap.get(month)}
                                            >
                                                <Tab.Container
                                                    id={`${year}-${month}-lwr-tabs`}
                                                    defaultActiveKey={Object.keys(reports[Number(year)][month])[0]}
                                                >
                                                    <Row>
                                                        <Col sm={3}>
                                                            <Nav 
                                                                variant="pills"
                                                                className="flex-column"
                                                            >
                                                                {Object.keys(reports[Number(year)][month]).map(lwr => {
                                                                    return (
                                                                        <Nav.Item>
                                                                            <Nav.Link
                                                                                eventKey={lwr}
                                                                                href="#"
                                                                            >
                                                                                {lwr}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    )
                                                                })}
                                                            </Nav>
                                                            <br></br>
                                                        </Col>
                                                        <Col sm={9}>
                                                            <Tab.Content>
                                                                {Object.keys(reports[Number(year)][month]).map(lwr => {
                                                                    return (
                                                                        <Tab.Pane
                                                                            eventKey={lwr}
                                                                        >
                                                                            <Table striped bordered hover responsive size="sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>№</th>
                                                                                    <th>Тип</th>
                                                                                    <th>Доверитель</th>
                                                                                    <th>Сумма списания</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {reports[Number(year)][month][lwr].map((report) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{report.agreement}</td>
                                                                                            <td>{report.type}</td>
                                                                                            <td>{report.client}</td>
                                                                                            <td>{report.debit}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td colSpan={3}>Итог</td>
                                                                                    <td>{totalDebit[Number(year)][month][lwr]}</td>
                                                                                </tr>
                                                                            </tfoot>

                                                                            </Table>
                                                                        </Tab.Pane>
                                                                    )
                                                                })}
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </Tab>
                                        )
                                    })}
                                </Tabs>
                            </Tab>
                        )
                    })}

                </Tabs>
            }
        </Container>
    );
};

export default observer(ReportsMainTable);
