import { makeAutoObservable } from 'mobx';
import AuthService from '../services/AuthService';
import DatabaseService from '../services/DatabaseService'
import axios from 'axios';
import { IAuthResponse } from '../models/response/AuthResponse';
import { API_URL } from '../http';

export default class Store {
    login = '';
    roles: Array<string> = [];
    isAuth = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
        this.setLoading.bind(this);
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(login: string, roles: Array<string>) {
        this.login = login;
        this.roles = roles;
    }

    async loginUser(login: string, password: string) {
        try {
            const response = await AuthService.login(login, password);
            console.log('response.data :>> ', response.data);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.login, response.data.roles);
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    async logout() {
        try {
            // this.setLoading(true);
            // const response = await AuthService.logout();
            localStorage.removeItem('token');
            console.log('token from storage', localStorage.getItem('token'));
            this.setAuth(false);
            this.setUser('', []);
            this.setLoading(false);
        } catch (error) {
            console.log('logout error :>> ', error);
            // this.setLoading(false);
        }
    }

    async checkAuth() {
        try {
            this.setLoading(true);
            const response = await axios.get<IAuthResponse>(
                `${API_URL}/user/refreshToken`,
                {
                    withCredentials: true
                }
            )
            console.log('checkAuth response :>> ', response);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.login, response.data.roles);
            this.setLoading(false);
        } catch (error) {
            console.log('checkAuth error :>> ', error);            
            this.setLoading(false);
        }
    }

    async getYears() {
        const response = await DatabaseService.getYears();
        return response.data;
    }

    async getReportYears() {
        const response = await DatabaseService.getReportYears();
        return response.data;
    }

    async getReportMonths(year: number) {
        const response = await DatabaseService.getReportMonths(year);
        console.log('getReportMonths => ', response);
        return response.data;
    }

    async getReportLawyers(year: number, month: string) {
        const response = await DatabaseService.getReportLawyers(year, month);
        return response.data;
    }

    async getJournal(year: string) {
        const response = await DatabaseService.getJournal(year);
        return response.data;
    }

    async getReport(year: number, month: string, lawyer: string) {
        const response = await DatabaseService.getReport(year, month, lawyer);
        return response.data;
    }

    async getAllReports() {
        const response = await DatabaseService.getReportsTable();
        return response.data;
    }   

}