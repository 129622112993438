import React, { FC, useState, useContext } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Context } from '..';
import { observer } from 'mobx-react-lite'

const LoginForm: FC = () => {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { store } = useContext(Context)

    return (
        <div>
            <Row>
                <Col sm={3}></Col>

                <Col sm={6}><Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Логин</Form.Label>
                    <Form.Control 
                        type="login" 
                        placeholder="Введите имя пользователя" 
                        onChange={
                            (event: { target: { value: React.SetStateAction<string>; }; }) => setLogin(event.target.value)
                        }
                        value={login}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control 
                        type="password" 
                        placeholder="Введите пароль"
                        onChange={
                            (event: { target: { value: React.SetStateAction<string>; }; }) => setPassword(event.target.value)
                        }
                        value={password}
                    />
                  </Form.Group>
                  <Button 
                    variant="primary" 
                    type="button"
                    onClick={() => store.loginUser(login, password)}
                    >
                    Войти
                  </Button>
                </Form></Col>
                <Col sm={3}></Col>
            </Row>

        </div>
    );
};

export default observer(LoginForm);
